export var MoodColorsTailwind = /* @__PURE__ */ ((MoodColorsTailwind2) => {
  MoodColorsTailwind2["POSITIVE"] = "teal";
  MoodColorsTailwind2["NEUTRAL"] = "slate";
  MoodColorsTailwind2["MIXED"] = "orange";
  MoodColorsTailwind2["NEGATIVE"] = "rose";
  return MoodColorsTailwind2;
})(MoodColorsTailwind || {});
export var MoodColorsTextTailwind = /* @__PURE__ */ ((MoodColorsTextTailwind2) => {
  MoodColorsTextTailwind2["POSITIVE"] = "emerald-900";
  MoodColorsTextTailwind2["NEUTRAL"] = "slate-900";
  MoodColorsTextTailwind2["MIXED"] = "orange-800";
  MoodColorsTextTailwind2["NEGATIVE"] = "rose-800";
  return MoodColorsTextTailwind2;
})(MoodColorsTextTailwind || {});
export var MoodTypeBadge = /* @__PURE__ */ ((MoodTypeBadge2) => {
  MoodTypeBadge2["POSITIVE"] = "success";
  MoodTypeBadge2["NEUTRAL"] = "neutral";
  MoodTypeBadge2["MIXED"] = "warning";
  MoodTypeBadge2["NEGATIVE"] = "error";
  return MoodTypeBadge2;
})(MoodTypeBadge || {});
export var Moods = /* @__PURE__ */ ((Moods2) => {
  Moods2["POSITIVE"] = "POSITIVE";
  Moods2["NEUTRAL"] = "NEUTRAL";
  Moods2["MIXED"] = "MIXED";
  Moods2["NEGATIVE"] = "NEGATIVE";
  return Moods2;
})(Moods || {});
